<template>
  <div class="flex-column">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search  :searchList="$sjglXtrzSearch" :listQuery='listQuery' :logTypeOption='$store.state.logType'  v-on:search='handleFilter' v-on:btn-event="onBtnClicked"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <div class="bg-white" style="height: 100%;" id="father-height">
          <u-table use-virtual   ref="mainTable"  showBodyOverflow="tooltip" showHeaderOverflow="title" :height="height"  :row-height="rowHeight"   class="longYpltable"  :data="list"  border style="width: 100%;" >
          <!-- 序号 -->
          <u-table-column type="index" width="50"  label="序号" align="center" key="hasIndex" > </u-table-column> 
          <u-table-column :label="'日志分类'" show-overflow-tooltip align="center" width="150px" >
            <template slot-scope="scope">
              <span>{{scope.row.typeName}}</span>
            </template>
          </u-table-column>
          <u-table-column :label="'日志内容'" show-overflow-tooltip align="center" min-width="300px">
            <template slot-scope="scope">
              <span>{{scope.row.content}}</span>
            </template>
          </u-table-column>
          <u-table-column :label="'模块'" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              <span>{{scope.row.href}}</span>
            </template>
          </u-table-column>

          <u-table-column :label="'操作人'" show-overflow-tooltip align="center" width="150px" >
            <template slot-scope="scope">
              <span>{{scope.row.createUserName}}</span>
            </template>
          </u-table-column>
          <u-table-column :label="'操作结果'" show-overflow-tooltip align="center" width="100px" >
            <template slot-scope="scope">
              <span :class="scope.row.result?'color-danger':'color-success'">{{scope.row.result?'失败':'成功'}}</span>
            </template>
          </u-table-column>
          <!-- <u-table-column :label="'所属应用'" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              <span>{{scope.row.application}}</span>
            </template>
          </u-table-column> -->
          <u-table-column :label="'日志时间'" show-overflow-tooltip align="center" width="180">
            <template slot-scope="scope">
              <span>{{scope.row.createTime}}</span>
            </template>
          </u-table-column>
          </u-table>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="handleCurrentChange" />
      </div>
    </div>
  </div>

</template>

<script>
  
  import * as sysLogs from '@/api/syslogs'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import Pagination from '@/components/Pagination'
  import elDragDialog from '@/directive/el-dragDialog'
  import extend from "@/extensions/delRows.js"
  export default {
    name: 'sysLog',
    components: {
      Sticky,
      Pagination,
    },
    mixins: [extend],
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        height:500,
        rowHeight: 30,
        multipleSelection: [], // 列表checkbox选中的值
        tableKey: 0,
        list: [],
        total: 0,
        listLoading: true,
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          logTypeName:'',
          key: undefined,
          appId: undefined
        },
        temp: {
          id: '', // Id
          content: '', // 日志内容
          typeName: '', // 分类名称
          typeId: '', // 分类ID
          href: '', // 操作所属模块地址
          createTime: '', // 记录时间
          createId: '', // 操作人ID
          createName: '', // 操作人
          ip: '', // 操作机器的IP地址
          result: '', // 操作的结果：0：成功；1：失败；
          application: '', // 所属应用
          extendInfo: '' // 其他信息,防止最后加逗号，可以删除
        },
        dialogFormVisible: false,
        dialogStatus: '',
      }
    },
    filters: {
    },
    created() {
    },
    activated() {
      // this.getList()
    },
    mounted() {
      this.getList()
      console.log(
        this.$sjglXtrzSearch
      )
      this.$nextTick(()=>{
        let ele=document.getElementById("father-height");
          this.height=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("father-height");
          this.height=ele.offsetHeight-50
          this.$forceUpdate()
        });
      })
    },
    methods: {
      rowClick(row) {
        this.$refs.mainTable.clearSelection()
        this.$refs.mainTable.toggleRowSelection(row)
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      onBtnClicked: function(domId) {
        switch (domId) {
          case 'btnAdd':
            this.handleCreate()
            break
          case 'btnEdit':
            if (this.multipleSelection.length !== 1) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            this.handleUpdate(this.multipleSelection[0])
            break
          case 'btnDel':
            if (this.multipleSelection.length < 1) {
              this.$message({
                message: '至少删除一个',
                type: 'error'
              })
              return
            }
            this.delrows(sysLogs, this.multipleSelection)
            break
          default:
            break
        }
      },
      getList() {
        this.listLoading = true
        sysLogs.getList(this.listQuery).then(response => {
          this.list = response.data
          this.total = response.count
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      handleModifyStatus(row, disable) { // 模拟修改状态
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        row.disable = disable
      },
      resetTemp() {
        this.temp = {
          id: '',
          content: '',
          typeName: '',
          typeId: '',
          href: '',
          createTime: '',
          createId: '',
          createName: '',
          ip: '',
          result: '',
          application: '',
          extendInfo: ''
        }
      }
    }
  }
</script>
<style>
  html{
    overflow: hidden;
  }
  .dialog-mini .el-select {
    width: 100%;
  }
  .el-tooltip__popper {
    display: -webkit-box !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 15 !important;
    -webkit-box-orient: vertical !important;
  }
</style>
