var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              attrs: {
                searchList: _vm.$sjglXtrzSearch,
                listQuery: _vm.listQuery,
                logTypeOption: _vm.$store.state.logType,
              },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "app-container flex-item" }, [
        _c(
          "div",
          {
            staticClass: "bg-white",
            staticStyle: { height: "100%" },
            attrs: { id: "father-height" },
          },
          [
            _c(
              "u-table",
              {
                ref: "mainTable",
                staticClass: "longYpltable",
                staticStyle: { width: "100%" },
                attrs: {
                  "use-virtual": "",
                  showBodyOverflow: "tooltip",
                  showHeaderOverflow: "title",
                  height: _vm.height,
                  "row-height": _vm.rowHeight,
                  data: _vm.list,
                  border: "",
                },
              },
              [
                _c("u-table-column", {
                  key: "hasIndex",
                  attrs: {
                    type: "index",
                    width: "50",
                    label: "序号",
                    align: "center",
                  },
                }),
                _c("u-table-column", {
                  attrs: {
                    label: "日志分类",
                    "show-overflow-tooltip": "",
                    align: "center",
                    width: "150px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.typeName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("u-table-column", {
                  attrs: {
                    label: "日志内容",
                    "show-overflow-tooltip": "",
                    align: "center",
                    "min-width": "300px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.content))])]
                      },
                    },
                  ]),
                }),
                _c("u-table-column", {
                  attrs: {
                    label: "模块",
                    "show-overflow-tooltip": "",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.href))])]
                      },
                    },
                  ]),
                }),
                _c("u-table-column", {
                  attrs: {
                    label: "操作人",
                    "show-overflow-tooltip": "",
                    align: "center",
                    width: "150px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.createUserName)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("u-table-column", {
                  attrs: {
                    label: "操作结果",
                    "show-overflow-tooltip": "",
                    align: "center",
                    width: "100px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.result
                                ? "color-danger"
                                : "color-success",
                            },
                            [_vm._v(_vm._s(scope.row.result ? "失败" : "成功"))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("u-table-column", {
                  attrs: {
                    label: "日志时间",
                    "show-overflow-tooltip": "",
                    align: "center",
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total>0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.page,
                limit: _vm.listQuery.limit,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "limit", $event)
                },
                pagination: _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }